<!-- Ex to use
data: {
  required: true,
  btnLabel: 'Mudando Texto do botão'
} -->

<template>
  <div
    v-if="dataOptions.input"
    ref="formWrapper"
    class="input-component chat-textarea">
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-layout>
          <v-text-field
            v-model="message"
            textarea
            required
            :counter="500"
            class="textarea"
            :hint="setHint"
            :persistent-hint="persistentHint"
            :rules="setRule"
            @keypress.enter="save" />
          <v-btn
            v-if="isMobile"
            :disabled="!valid"
            color="accent"
            class="btn-mobile-save"
            data-cy="btnSave"
            icon
            @click.native="save">
            <v-icon small>
              mdi-send
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            color="accent"
            class="btn-big"
            data-cy="btnSave"
            @click.native="save">
            {{ setLabelButton }}
          </v-btn>
          <v-btn
            v-if="isMobile"
            color="gray"
            icon
            dark
            class="btn-mobile-prev"
            title="Selecionar outro motivo"
            data-cy="btnPrev"
            @click.native="prev">
            <v-icon small>
              arrow_back_ios
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color=""
            class="btn-big"
            title="Selecionar outro motivo"
            data-cy="btnBack"
            @click.native="prev">
            Outro Motivo
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
  <message
    v-else
    :data="data" />
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import validate from '@/mixins/validate'

  export default {
    components: { Message },
    mixins: [chatUiMixin, validate],
    data () {
      return {
        message: '',
        valid: true
      }
    },
    computed: {
      dataOptions () {
        return this.data
      },
      setRule () {
        return this.data.required ? this.maxLength500 : []
      },
      setLabelButton () {
        return this.data.btnLabel ? this.data.btnLabel : 'Enviar'
      },
      setHint () {
        return this.data.hint ? this.data.hint : ''
      },
      persistentHint () {
        return this.data.hint !== null
      }
    },
    mounted () {
      this.startAnimation()
      this.$nextTick(() => {
        document.querySelector('.textarea textarea') !== null && this.focus()
      })
    },
    methods: {
      save (ev) {
        ev.preventDefault()
        if (this.$refs.form.validate()) {
          let callback = () => {
            let option = {
              id: this.data.id,
              value: this.message
            }
            this.$emit('send', option)
          }

          this.endAnimation(callback)
        }
      },
      focus () {
        document.querySelector('.textarea textarea').focus()
      },
      prev () {
        let option = {
          id: this.data.id,
          value: 'prev'
        }
        this.$emit('send', option)
      }
    }
  }
</script>

<style lang="sass">
  .chat-textarea
    top: -35px
    max-width: 100%
    margin: 0 auto

    .v-input-group
      padding-top: 0

    .v-input-group--text-field.v-input-group--textarea

      .v-input-group__input
        padding: 10px
        border: 2px solid $bgColor

        textarea
          height: 45px

      .v-input-group__details
        padding-top: 0

    .textarea
      margin-right: 10px


    .btn-mobile-save,
    .btn-mobile-prev
      margin-top: 10px

    // .btn
    //   height: 70px
    //   margin: 0 0 0 15px

</style>
